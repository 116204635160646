<template>
  <div>
    <!-- <div class="mb-1">
      <b-card>
        <ComplaintCreateModal @modalClosed="onModalClosedCreate" />
      </b-card>
    </div> -->
    <b-card class="text-black">
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="firstNameFilter"
            v-model="firstNameFilter"
            placeholder="Search by first name"
          />
        </div>

        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="lastNameFilter"
            v-model="lastNameFilter"
            placeholder="Search by last name"
          />
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-input
            id="markedFromFilter"
            v-model="markedFromFilter"
            placeholder="Marked from cnic"
          />
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <span class="mr-50">Marked To: </span>
          <v-select
            inputId="id"
            label="name"
            v-model="markedToFilter"
            :options="organizations"
            autoscroll
            :clearable="true"
            placeholder="Marked to"
          ></v-select>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <span class="mr-50">Search by status: </span>
          <v-select
            inputId="id"
            label="name"
            v-model="statusTypeFilter"
            :options="statusTypes"
            autoscroll
            :clearable="true"
          ></v-select>
        </div>
        <!-- <div class=" d-flex justify-content-center align-items-center flex-nowrap mr-5 ">
          <span class="mr-50">Search by regiment: </span>
          <v-select inputId="id" label="name" v-model="regimentFilter" :options="regiments" autoscroll
            :clearable="true"></v-select>
        </div> -->
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <span class="mr-50">Search by category: </span>
          <v-select
            inputId="id"
            label="name"
            v-model="categoryFilter"
            :options="categories"
            autoscroll
            :clearable="true"
          ></v-select>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <span class="mr-50">Search by subcategory: </span>
          <v-select
            inputId="id"
            label="name"
            v-model="subcategoryFilter"
            :options="selectedSubcategories"
            autoscroll
            :clearable="true"
          ></v-select>
        </div>
      </div>
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </div>
      </div>

      <b-card>
        <b-table
          :fields="fields"
          :items="complaints"
          details-td-class="m-0 p-0"
          small
          v-if="
            (hasPermission('read_complaint') && hasRole('op')) || hasRole('su')
          "
          :per-page="0"
          :tbody-tr-class="rowClass"
          responsive
          table-class="w-100"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #custom-foot>
            <b-tr variant="light">
              <b-td :colspan="fields.length">
                <span class="font-weight-bolder"
                  >Total Rows: {{ totalItems }}</span
                >
              </b-td>
            </b-tr>
          </template>
          <template #cell(status)="row">
            {{ statusNames(row.item.status) }}
          </template>
          <template #cell(category)="row">
            {{ row.item.category_data ? row.item.category_data.name : "" }}
          </template>
          <template #cell(rank_type)="row">
            {{ rankTypeName(row.item.rank_type) }}
          </template>
          <template #cell(draft)="row">
            {{ row.item.draft ? "Yes" : "No" }}
          </template>
          <template #cell(approved_by_data)="row">
            {{
              row.item.approved_by_data
                ? `${row.item.approved_by_data.appointment_name} -
                        ${row.item.approved_by_data.name}`
                : ""
            }}
          </template>
          <template #cell(approved_at)="row">
            {{
              row.item.approved_at ? getDateString(row.item.approved_at) : ""
            }}
          </template>
          <template #cell(created_at)="row">
            {{ getDateString(row.item.created_at) }}
          </template>
          <template #cell(updated_at)="row">
            {{ getDateString(row.item.updated_at) }}
          </template>
          <template #cell(created_by_data)="row">
            {{
              row.item.created_by_data
                ? `
            ${row.item.created_by_data.name}`
                : ""
            }}
          </template>
          <template #cell(updated_by_data)="row">
            {{
              row.item.updated_by_data
                ? `
            ${row.item.updated_by_data.name}`
                : ""
            }}
          </template>
          <!-- <template #cell(history)="row">
            Marked From: {{ row.item.history[row.item.history.length - 1].marked_from_data.appointment_name }}-{{
              row.item.history[row.item.history.length - 1].marked_from_data.cnic }}
            <br />
            Marked To: {{ row.item.history[row.item.history.length - 1].marked_from_data.appointment_name }}-{{
              row.item.history[row.item.history.length - 1].marked_to_data.cnic }}
          </template> -->
          <template #cell(marked_at)="row">
            {{ row.item.marked_at ? getDateString(row.item.marked_at) : "" }}
          </template>
          <template #cell(manage)="row">
            <b-button
              variant="info"
              pill
              size="sm"
              @click="editComplaint(row.item)"
              v-if="hasPermission('update_complaint') && !row.item.draft"
            >
              Detail
            </b-button>
            <b-button
              variant="success"
              pill
              class="ml-50"
              size="sm"
              @click="receiveComplaint(row.item)"
              v-if="
                hasPermission('update_complaint') &&
                !row.item.draft &&
                !row.item.downloaded
              "
            >
              Received
            </b-button>
            <b-button
              variant="danger"
              pill
              class="ml-50"
              size="sm"
              @click="closeComplaint(row.item)"
              v-if="
                hasPermission('close_complaint') &&
                !row.item.draft &&
                row.item.status !== complaintStatus.CLOSED
              "
            >
              Close
            </b-button>
          </template>
        </b-table>

        <b-table
          :fields="fields1"
          :items="complaints"
          details-td-class="m-0 p-0"
          small
          v-else
          :per-page="0"
          :tbody-tr-class="rowClass"
          responsive
          table-class="w-100"
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <!-- <template #custom-foot>
            <b-tr variant="light">
              <b-td :colspan="fields.length">
                <span class="font-weight-bolder">Total Rows: {{ totalItems }}</span>
              </b-td>
            </b-tr>
          </template>
          <template #cell(status)="row">
            {{ statusNames(row.item.status) }}
          </template>
          <template #cell(category)="row">
            {{ row.item.category_data ? row.item.category_data.name : "" }}
          </template>
          <template #cell(rank_type)="row">
            {{ rankTypeName(row.item.rank_type) }}
          </template>
          <template #cell(draft)="row">
            {{ row.item.draft ? "Yes" : "No" }}
          </template>
          <template #cell(approved_by_data)="row">
            {{
              row.item.approved_by_data
              ? `${row.item.approved_by_data.appointment_name} -
                        ${row.item.approved_by_data.name}`
              : ""
            }}
          </template>
          <template #cell(approved_at)="row">
            {{ row.item.approved_at ? getDateString(row.item.approved_at) : "" }}
          </template>
          <template #cell(created_at)="row">
            {{ getDateString(row.item.created_at) }}
          </template>
          <template #cell(updated_at)="row">
            {{ getDateString(row.item.updated_at) }}
          </template>
          <template #cell(created_by_data)="row">
            {{
              row.item.created_by_data
              ? `
            ${row.item.created_by_data.name}`
              : ""
            }}
          </template>
          <template #cell(updated_by_data)="row">
            {{
              row.item.updated_by_data
              ? `
            ${row.item.updated_by_data.name}`
              : ""
            }}
          </template> -->
          <!-- <template #cell(history)="row">
            Marked From: {{ row.item.history[row.item.history.length - 1].marked_from_data.appointment_name }}-{{
              row.item.history[row.item.history.length - 1].marked_from_data.cnic }}
            <br />
            Marked To: {{ row.item.history[row.item.history.length - 1].marked_from_data.appointment_name }}-{{
              row.item.history[row.item.history.length - 1].marked_to_data.cnic }}
          </template> -->
          <!-- <template #cell(marked_at)="row">
            {{ row.item.marked_at ? getDateString(row.item.marked_at) : "" }}
          </template> -->
          <template #cell(manage)="row">
            <b-button
              variant="success"
              pill
              size="sm"
              @click="editComplaint(row.item)"
              v-if="hasPermission('update_complaint') && !row.item.draft"
            >
              Detail
            </b-button>
            <b-button
              variant="danger"
              pill
              class="ml-50"
              size="sm"
              @click="closeComplaint(row.item)"
              v-if="
                hasPermission('close_complaint') &&
                !row.item.draft &&
                row.item.status !== complaintStatus.CLOSED
              "
            >
              Close
            </b-button>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
          class="d-flex justify-content-center"
        ></b-pagination>
        <!-- <span class="mt-1 bg1 float-right" >Total Rows: {{ rows }}</span> -->
      </b-card>

      <complaint-edit-modal
        :complaint="complaint"
        :allowEdit="allowEdit"
        :markingAllowed="markingAllowed"
        @modalClosed="onModalClosed"
        :key="`edit-${complaintEditModalCount}`"
      />
      <complaint-close-modal
        :complaint="complaint"
        @modalClosed="onModalClosed"
        :key="`closed-${complaintCloseModalCount}`"
      />
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ComplaintCreateModal from "@/components/complaint/ComplaintCreateModal.vue";
import ComplaintEditModal from "@/components/complaint/ComplaintEditModal.vue";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import util from "@/util.js";
import ComplaintCloseModal from "@/components/complaint/ComplaintCloseModal.vue";

export default {
  components: {
    ComplaintCreateModal,
    ComplaintEditModal,
    ComplaintCloseModal,
    VueSelectPaginated,
  },
  mixins: [util],
  data() {
    return {
      fields: [
        { key: "manage", label: "Manage" },
        // { key: "case_no", label: "Case No" },
        { key: "old_case_no", label: "Case No" },
        // { key: "category_data.parent_name", label: "Category" },
        // { key: "category", label: "SubCategory" },
        // { key: "type_data.name", label: "Type" },
        // { key: "rank_type", label: "Rank Type" },
        // { key: "priority_data.name", label: "Priority" },
        // { key: "draft", label: "Draft" },
        { key: "status", label: "Status" },
        // { key: `history`, label: "Marked By" },
        // { key: "marked_at", label: "Marked On" },
        // { key: "approved_at", label: "Approved At" },
        // { key: "approved_by_data", label: "Approved By" },
        { key: "created_at", label: "Created on" },
        { key: "created_by_data", label: "Created By" },
        // { key: "updated_at", label: "Updated At" },
        { key: "updated_by_data", label: "Updated By" },
      ],
      fields1: [
        { key: "manage", label: "Manage" },
        { key: "case_no", label: "Case No" },
        // { key: "category_data.parent_name", label: "Category" },
        // { key: "category", label: "SubCategory" },
        // { key: "type_data.name", label: "Type" },
        // { key: "rank_type", label: "Rank Type" },
        // { key: "priority_data.name", label: "Priority" },
        // { key: "draft", label: "Draft" },
        { key: "status", label: "Status" },
        // { key: `history`, label: "Marked By" },
        // { key: "marked_at", label: "Marked On" },
        // { key: "approved_at", label: "Approved At" },
        // { key: "approved_by_data", label: "Approved By" },
        // { key: "created_at", label: "Created on" },
        // { key: "created_by_data", label: "Created By" },
        // // { key: "updated_at", label: "Updated At" },
        // { key: "updated_by_data", label: "Updated By" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      complaints: [],
      complaint: null,
      complaintCreateModalCount: 0,
      complaintEditModalCount: 0,
      complaintDraftModalCount: 0,
      complaintDetailModalCount: 0,
      complaintCloseModalCount: 0,
      prefix: null,
      armyNo: "",
      cnic: "",
      status: null,
      caseNo: "",
      title: "",
      mobileNumber: "",
      fromDate: "",
      toDate: "",
      sponsorOrganization: null,
      searchType: { value: 1, name: "Case No" },
      allowEdit: true,
      markingAllowed: false,
      isBusy: true,

      firstNameFilter: "",
      lastNameFilter: "",
      complaintCnicFilter: "",
      markedFromFilter: "",
      markedToFilter: null,
      categoryFilter: null,
      subcategoryFilter: null,
      requestSourceFilter: null,
      callerTypeFilter: null,
      relationTypeFilter: null,
      complainantTypeFilter: null,
      complaintTypeFilter: null,
      statusTypeFilter: null,
      armyPrefixeFilter: null,
      maritalStatusFilter: null,
      regimentFilter: null,
      servingStatusFilter: null,
      rankFilter: null,
      cityFilter: null,

      requestSources: [],
      callerTypes: [],
      relationTypes: [],
      complainantTypes: [],
      complaintTypes: [],
      statusTypes: [],
      armyPrefixes: [],
      maritalStatuses: [],
      regiments: [],
      servingStatuses: [],
      ranks: [],
      cities: [],
      categories: [],
      subcategories: [],
      selectedSubcategories: [],

      organizations: [],
      show: false,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      // This code will be executed after the child components are created and mounted.
    });

    try {
      // await this.fetchPaginatedData();
      const res = await this.getOrganizationsUnpaginated();
      this.organizations = res.data;
      await this.search();
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getComplaints: "appData/getComplaints",
      getComplaint: "appData/getComplaint",
      getComplaintFormFields: "appData/getComplaintFormFields",
      getOrganizationsUnpaginated: "appData/getOrganizationsUnpaginated",
      updateComplaint: "appData/updateComplaint",
      // getOrganizations: "appData/getOrganizations",
    }),
    async search() {
      this.isBusy = true;
      // if (this.searchType) {
      //   switch (this.searchType.value) {
      //     case 1:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.cnic = "";
      //       this.title = "";
      //       this.status = null;
      //       this.mobileNumber = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 2:
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.title = "";
      //       this.status = null;
      //       this.mobileNumber = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 3:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.title = "";
      //       this.status = null;
      //       this.mobileNumber = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 4:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.status = null
      //       this.mobileNumber = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 5:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.title = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 6:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.status = "";
      //       this.title = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 7:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.status = "";
      //       this.title = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       this.sponsorOrganization = null;
      //       break;
      //     case 8:
      //       this.prefix = null;
      //       this.armyNo = "";
      //       this.caseNo = "";
      //       this.cnic = "";
      //       this.status = "";
      //       this.title = "";
      //       this.fromDate = "";
      //       this.toDate = "";
      //       break;
      //   }
      // }
      this.currentPage = 1;
      await this.getComplaintFiltersData();
      await this.fetchPaginatedData();
      this.isBusy = false;
    },
    async getComplaintFiltersData() {
      try {
        const res = await this.getComplaintFormFields();
        this.requestSources = res.data.request_sources;
        this.callerTypes = res.data.caller_types;
        this.relationTypes = res.data.relation_types;
        this.complainantTypes = res.data.complainant_types;
        this.complaintTypes = res.data.complaint_types;
        this.statusTypes = res.data.statuses;
        this.armyPrefixes = res.data.army_prefixes;
        this.maritalStatuses = res.data.marital_statuses;
        this.regiments = res.data.regiments;
        this.servingStatuses = res.data.serving_statuses;
        this.ranks = res.data.rank_types;
        this.cities = res.data.cities;
        this.categories = res.data.categories;
        this.subcategories = res.data.subcategories;
        this.selectedSubcategories = this.subcategories;
      } catch (error) {
        this.displayError(error);
      }
    },

    async fetchPaginatedData() {
      try {
        let tempParams = {
          pageNumber: this.currentPage,
          draft: false,
        };
        if (this.firstNameFilter) {
          tempParams["first_name"] = this.firstNameFilter;
        }
        if (this.lastNameFilter) {
          tempParams["last_name"] = this.lastNameFilter;
        }
        if (this.markedFromFilter) {
          tempParams["marked_from_cnic"] = this.markedFromFilter;
        }
        if (this.markedToFilter) {
          tempParams["marked_to_organization"] = this.markedToFilter.id;
        }
        if (this.armyNo) {
          tempParams["army_no"] = this.armyNo;
        }
        if (this.cnic) {
          tempParams["cnic"] = this.cnic;
        }
        if (this.caseNo) {
          tempParams["old_case_no"] = this.caseNo;
        }
        // if (this.caseNo) {
        //   tempParams['case_no'] = this.caseNo
        // }
        if (this.statusTypeFilter) {
          tempParams["status"] = this.statusTypeFilter.id;
        }
        if (this.mobileNumber) {
          tempParams["mobile_number"] = this.mobileNumber;
        }
        if (this.fromDate) {
          tempParams["from_date"] = this.fromDate;
        }
        if (this.toDate) {
          tempParams["to_date"] = this.toDate;
        }
        if (this.prefix) {
          tempParams["army_prefix"] = this.prefix.id;
        }
        if (this.categoryFilter) {
          tempParams["category"] = this.categoryFilter.id;
        }
        if (this.subcategoryFilter) {
          tempParams["subcategory"] = this.subcategoryFilter.id;
        }

        const res = await this.getComplaints(tempParams);
        this.complaints = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },

    createComplaint() {
      this.complaintCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("complaint-create-modal");
      });
    },
    editComplaint(complaint) {
      this.complaint = complaint;
      this.allowEdit = true;

      if (
        this.complaint.status == this.complaintStatus.FINALIZED ||
        this.complaint.status == this.complaintStatus.CLOSED
      ) {
        this.allowEdit = false;
      }

      // if (((this.complaint.marked_from == this.getLoggedInUser.id) || (this.getLoggedInUser.role == 'su')) && (complaint.draft)) {
      //   this.markingAllowed = true
      // }
      // else {
      //   this.markingAllowed = false
      // }

      this.markingAllowed = true;
      if (complaint.draft) {
        this.isDraft = true;
      } else {
        this.isDraft = false;
      }
      this.complaintEditModalCount += 1;

      this.$nextTick(() => {
        this.$bvModal.show("complaint-edit-modal");
      });
    },
    async receiveComplaint(complaint) {
      this.show = true;
      try {
        const res = await this.updateComplaint({
          payload: {
            downloaded: true,
            updated_by: this.getLoggedInUser.id,
          },
          pk: complaint.id,
        });
        if (res.status === 200) {
          this.show = false;
          this.$swal({
            title: "Complaint received successfully",
            icon: "success",
            timer: 1000,
          });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
      await this.search();
    },

    closeComplaint(complaint) {
      this.complaint = complaint;
      this.complaintCloseModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("complaint-close-modal");
      });
    },
    async onModalClosed() {
      this.markingAllowed = false;
      await this.fetchPaginatedData();
    },
    async onModalClosedCreate() {
      await this.fetchPaginatedData();
    },
    getMarkedBy(history) {
      for (let i = history.length - 1; i >= 0; i--) {
        const hist = history[i];
        if (hist.marked_to === this.getLoggedInUser.id) {
          return `${hist.marked_from_data.appointment_name} - ${hist.marked_from_data.name}`;
        }
      }
    },
    getMarkedOn(history) {
      for (let i = history.length - 1; i >= 0; i--) {
        const hist = history[i];
        if (hist.marked_to === this.getLoggedInUser.id) {
          return `${hist.marked_at}`;
        }
      }
    },
    async removeComplaint(complaint) {
      this.complaint = complaint;
      try {
        const res = await this.deleteComplaint({
          pk: this.complaint.id,
        });
        if (res.status === 204) {
          this.$swal({
            title: "Complaint deleted successfully",
            icon: "success",
          });
          if (
            (this.totalItems - 1) % this.perPage === 0 &&
            this.currentPage !== 1
          ) {
            this.currentPage -= 1;
          } else {
            await this.fetchPaginatedData();
          }
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    nextComplaint() {
      let complaintIndex = 0;
      for (let i = 0; i < this.complaints.length; i++) {
        const element = this.complaints[i];
        if (element.id == this.complaint.id) {
          complaintIndex = i;
          break;
        }
      }
      complaintIndex += 1;
      if (complaintIndex > this.complaints.length - 1) {
        this.displayError("No More Complaints");
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide("complaint-detail-modal");
      });
      this.complaint = this.complaints[complaintIndex];
      this.complaintDetailModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("complaint-detail-modal");
      });
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
    categoryFilter(newValue, oldValue) {
      if (newValue) {
        if (oldValue) {
          this.subcategoryFilter = null;
        }
        this.selectedSubcategories = [];
        for (let i = 0; i < this.subcategories.length; i++) {
          if (this.subcategories[i].parent == newValue.id) {
            this.selectedSubcategories.push(this.subcategories[i]);
          }
        }
        if (
          this.selectedSubcategories === undefined ||
          this.selectedSubcategories.length == 0
        ) {
        }
      } else {
        this.selectedSubcategories = this.subcategories;
      }
    },
  },
};
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>
<style scoped>
::placeholder {
  color: black;
}
</style>
