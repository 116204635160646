import { render, staticRenderFns } from "./ComplaintCloseModal.vue?vue&type=template&id=746ea45d&scoped=true&"
import script from "./ComplaintCloseModal.vue?vue&type=script&lang=js&"
export * from "./ComplaintCloseModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746ea45d",
  null
  
)

export default component.exports