var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"complaint-close-modal","title":"Complaint Close Modal","centered":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true},on:{"hidden":_vm.reset},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Close Complaint")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-form-group',{staticClass:"mt-1 text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Close ")])],1)]},proxy:true}])},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"complaintCloseFormValidation"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"finalComment"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Final Comment "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Final Comment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"char-textarea",class:_vm.finalComment.length === 0 ||
                                _vm.finalComment.length >= _vm.maxCommentCount
                                ? 'text-danger'
                                : '',attrs:{"id":"finalComment","placeholder":"Final Comment","rows":"4","state":errors.length >
                                0
                                ? false
                                : null ||
                                (_vm.finalComment.length > 0 &&
                                _vm.finalComment.length <= _vm.maxCommentCount),"no-resize":""},model:{value:(_vm.finalComment),callback:function ($$v) {_vm.finalComment=$$v},expression:"finalComment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{staticClass:"textarea-counter-value float-right",class:_vm.finalComment.length >= _vm.maxCommentCount ? 'bg-danger' : ''},[_c('span',{staticClass:"char-count"},[_vm._v(_vm._s(_vm.finalComment.length))]),_vm._v(" / "+_vm._s(_vm.maxCommentCount)+" ")])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }