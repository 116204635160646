<template>
    <b-modal id="complaint-close-modal" title="Complaint Close Modal" centered size="lg" @hidden="reset"
             :no-close-on-esc="true" :no-close-on-backdrop="true">
        <template #modal-title>
            <h2 class="m-0">Close Complaint</h2>
        </template>
        <b-form @submit.prevent>
            <validation-observer ref="complaintCloseFormValidation">
                <b-row>
                    <b-col md="12">
                        <b-form-group label-for="finalComment">
                            <template #label>
                                Final Comment
                                <span class="text-danger">*</span>
                            </template>
                            <validation-provider #default="{ errors }" name="Final Comment" rules="required">
                                <b-form-textarea id="finalComment" v-model="finalComment" placeholder="Final Comment" rows="4" :state="errors.length >
                                    0
                                    ? false
                                    : null ||
                                    (finalComment.length > 0 &&
                                    finalComment.length <= maxCommentCount)
                                    " class="char-textarea" :class="finalComment.length === 0 ||
                                    finalComment.length >= maxCommentCount
                                    ? 'text-danger'
                                    : ''
                                    " no-resize />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small class="textarea-counter-value float-right" :class="finalComment.length >= maxCommentCount ? 'bg-danger' : ''
                  ">
                                        <span class="char-count">{{ finalComment.length }}</span>
                                        /
                                        {{ maxCommentCount }}
                                    </small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </validation-observer>
        </b-form>
        <template #modal-footer>
            <b-form-group class="mt-1 text-right">
                <b-button type="submit" variant="primary" pill class="mr-1" @click="validationForm">
                    Close
                </b-button>
            </b-form-group>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import { required } from "@validations";
    import draggable from "vuedraggable";
    import util from "@/util.js";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            draggable,
        },
        props: {
            complaint: Object,
        },
        mixins: [util],
        data() {
            return {
                required,
                finalComment: "",
                maxCommentCount: 800,
            };
        },
        methods: {
            ...mapActions({
                updateComplaint: "appData/updateComplaint",
                update_status_to_close_send_sms: "appData/update_status_to_close_send_sms",
            }),
            async validationForm() {
                const success = await this.$refs.complaintCloseFormValidation.validate();
                if (success) {
                    await this.submit();
                }
            },
            async submit() {
                try {
                    const res = await this.update_status_to_close_send_sms({
                        type: 'COMPLAINT',
                        id: this.complaint.id,
                        final_comment: this.finalComment,
                    });
                    if (res.status === 200) {
                        this.$swal({
                            title: "Complaint closed successfully",
                            icon: "success",
                            timer: 2500,
                        });
                        this.reset();
                        this.$nextTick(() => {
                            this.$bvModal.hide("complaint-close-modal");
                        });
                        this.$emit("modalClosed");
                    }
                } catch (error) {
                    this.displayError(error);
                }
            },
            reset() {
                this.finalComment = "";
            },
        },
        computed: {
            ...mapGetters({ getLoggedInUser: "appData/getUser" }),
        },
    };
</script>

<style lang="scss" scoped></style>
